"use strict";

App.Section = {

    section             : '.js-section',
    sectionTitle        : '.js-section-title',
    sectionPresentation : '.js-section#presentation',

    init: function() {

        this.$section             = jQuery(this.section);
        this.$sectionTitle        = jQuery(this.sectionTitle);
        this.$sectionPresentation = jQuery(this.sectionPresentation);
        this.setSectionHeight(this.$sectionPresentation);

    },

    setSectionHeight: function($elem) {

        var self = this;

        $elem.outerHeight(App.Global.getWindowHeight());
        setTimeout(function() {
            self.setSectionTitleWidth();
        }, 200);


    },

    setSectionTitleWidth: function() {

        var self = this;
        self.$sectionTitle.innerWidth(self.$sectionTitle.parents(self.section).innerHeight());

    }

}
