"use strict";

App.Global = {

    preloader       : '.js-preloader',
    preloaderStatus : '.js-preloader-status',
    rippleButton    : ".js-ripple-button",
    fullPage        : '.js-fp',
    contact         : '.js-contact',

    init: function() {

        this.$preloader       = jQuery(this.preloader);
        this.$preloaderStatus = jQuery(this.preloaderStatus);
        this.$rippleButton    = jQuery(this.rippleButton);
        this.$fullPage        = jQuery(this.fullPage);
        this.$contact         = jQuery(this.contact);
        this.addListeners();
        this.initLazy();

    },

    addListeners: function() {

        var self = this;

        jQuery(window).on('load', function() {
            self.disablePreloader();
        });

        var cachedHeight = self.getWindowHeight();
        jQuery(window).on('resize', function() {

            var newHeight = self.getWindowHeight();
            if(newHeight !== cachedHeight){
                App.Section.setSectionHeight(App.Section.$sectionPresentation);
                App.Section.setSectionTitleWidth();
                cachedHeight = newHeight;
            }

        });

        self.$rippleButton.on("click", function(e) {
            self.triggerRipple(jQuery(this), e);
        })

        enquire.register("screen and (max-width:" + App.bp_phone_only + "px)", {

            setup: function() {

                App.Global.enableFullPage();
                jQuery(App.Reference.referenceSliderBody).find(".layer--slide").removeAttr("style");

            },

            match: function() {

                App.Global.destroyFullPage();
                if(jQuery(App.Reference.referenceSliderBody).hasClass("moved")){
        			App.Reference.$referenceSliderBody.insertAfter(App.Reference.$referenceSliderHeader).removeClass("moved ready");
                    jQuery(App.Layer.closeLayer).remove();
                }

            },

            unmatch: function() {

                App.Global.enableFullPage();
                jQuery(App.Reference.referenceSliderBody).find(".layer--slide").removeAttr("style");

            }

        });

        self.$contact.on('mouseenter', function() {

            jQuery(this).parents('.js-section').find('.section__title--big').css({
                'transition-delay':'0s',
                'z-index':'-1'
            });

        }).on('mouseleave', function() {

            jQuery(this).parents('.js-section').find('.section__title--big').css({
                'z-index':'5',
                'transition-delay':'.5'
            });

        });

    },

    getWindowHeight: function() {

        return jQuery(window).height();

    },

    getWindowWidth: function() {

        return jQuery(window).width();

    },

    enablePreloader: function() {

        var self = this;

        jQuery('body').attr('data-preload','true');
        self.$preloader.delay(250).fadeIn(450);
        self.$preloaderStatus.fadeIn(180);

    },

    disablePreloader: function() {

        var self = this;

        self.$preloaderStatus.fadeOut(180);
        self.$preloader.delay(450).fadeOut(250);
        jQuery('body').delay(700).removeAttr('data-preload');

    },

    enableFullPage: function() {

        var self = this;

        self.$fullPage.fullpage({
            sectionSelector: App.Section.section,
            responsiveWidth: App.bp_tabport_up,
            easing: 'easeInOutExpo',
            scrollingSpeed: 900,
            //anchors: ['page1', 'page2', 'page3', 'page4', 'page5'],
            afterLoad: function(anchorLink, index){

        		if(index == 2 && !App.Reference.$referenceSliderBody.hasClass("moved")){
        			App.Reference.$referenceSliderBody.appendTo('body').addClass("moved");
                    App.Reference.$referenceSliderBody.find(".owl-nav").appendTo('body');
                    jQuery.fn.fullpage.reBuild();

        		}

        	},
            onLeave: function(index, nextIndex, direction) {

                App.Navigation.manageCurrentClass(nextIndex-2);
                App.Section.$section.removeClass("anim--ready");
                if(direction === "down") {

                    App.Navigation.$navigation.addClass('navigation--up');

                }else{

                    App.Navigation.$navigation.removeClass('navigation--up');

                }

                setTimeout(function() {

                    App.Section.$section.eq(nextIndex-1).addClass("anim--ready");

                }, 200);

            }
        });

    },

    gotToSectionUp: function() {

        jQuery.fn.fullpage.moveSectionUp();

    },

    gotToSectionDown: function() {

        jQuery.fn.fullpage.moveSectionDown();

    },

    goToSection: function(id) {

        jQuery('html,body').animate({
            scrollTop: jQuery("#"+id).offset().top
        }, 800, 'easeInOutExpo' );

    },

    destroyFullPage: function() {

        var self = this;

        jQuery.fn.fullpage.destroy('all');

    },

    addRipple: function($that, e) {

        //e.preventDefault();
        var $this = $that;

        //remove ripple if it's already on
        var $oldRipple = jQuery('.ripple');
        if($this.has($oldRipple)) $oldRipple.remove();
        //If your page has more than one button instead of this just do $('.ripple').remove() to remove them all

        //create element
        var $ripple = jQuery(document.createElement('span'));
        $ripple.addClass('ripple');

        //Get the click's location
        var eY = e.offsetY;
        var eX = e.offsetX;

        //Get the element's width and height
        var w = $this.width();
        var h = $this.height();

        //get the offset of eX and eY
        var offsetX = Math.abs(w / 2 - eX);
        var offsetY = Math.abs(h / 2 - eY);

        //get the delta of X and Y
        var deltaX = w/2+offsetX;
        var deltaY = h/2+offsetY;

        //size
        //The squareroot of ( deltaX² + deltaY² - 2 * deltaX * deltaY * cos(90°) (in radian = 1/2 PI) ) * 2
        var size = Math.sqrt( Math.pow(deltaX,2) + Math.pow(deltaY,2) -2 * deltaX * deltaY * Math.cos(Math.PI / 2) ) * 2;

        //apply everything to $ripple
        $ripple.css({
          'top': eY,
          'left': eX,
          'height': size,
          'width': size
        });

        //and append it
        $this.append($ripple);

    },

    triggerRipple: function($that, e) {

        var self = this;
        self.addRipple($that, e);

    },

    initLazy: function() {

        var self = this;

        jQuery('.lazy-img').Lazy({
            visibleOnly: true
        });

    }

}
