"use strict";

var App = {
    // Viewport breakpoints declaration
    bp_phone_only: 599,
    bp_tabport_up: 600,
    bp_tabland_up: 900,
    bp_desk_up: 1200,
    bp_deskbig_up: 1800
};

jQuery(document).ready(function(){

    App.Global.init();
    App.Navigation.init();
    App.Section.init();
    App.PlusMinus.init();
    App.Layer.init();
    App.Reference.init();
    App.Video.init();
    App.Scroll.init();

});
