"use strict";

App.Navigation = {

    navigation        : '.js-navigation',
    navigationToggle  : '.js-nav-toggle',
    navigationMenu    : '.js-navigation-menu',
    navigationLink    : '.js-navigation-menu > li > a',
    navigationSubLink : '.js-navigation-menu .sub-menu a',
    lastScrollTop     : 0,
    delta             : 5,
    didScroll         : false,
    timer             : "",

    init: function() {

        this.$navigation        = jQuery(this.navigation);
        this.$navigationLink    = jQuery(this.navigationLink);
        this.navigationHeight   = this.$navigation.outerHeight();
        this.$navigationToggle  = jQuery(this.navigationToggle);
        this.$navigationMenu    = jQuery(this.navigationMenu);
        this.$navigationSubLink = jQuery(this.navigationSubLink);
        this.toggleTimer(true);
        this.addListeners();

    },

    addListeners: function() {

        var self = this;

        self.$navigationToggle.on("click", function() {
            var $that = jQuery(this);
            self.navToggleClass($that);
            self.menuToggleClass();
        })

        jQuery(window).on('scroll', function(event){
            self.didScroll = true;
        });

        self.$navigationSubLink.on('click', function(e) {
            e.preventDefault();
            var $that = jQuery(this);
            var target = $that.attr('data-target');
            self.goToReferences(target);
        });

        self.$navigationLink.on('click', function(e) {

            var targetString = jQuery(this).attr("href");
            var targetId = targetString.substring(1, targetString.length);
            var sectionAnchor = jQuery(this).attr("data-section");

            if(jQuery('.fp-enabled').length === 1) {

                e.preventDefault();
                if(App.Reference.$referenceSliderBody.hasClass('display')) {
                    App.Layer.hideFullScreenLayer(App.Reference.$referenceSliderBody);
                }
                jQuery.fn.fullpage.moveTo(sectionAnchor);

            } else {

                self.navToggleClass(self.$navigationToggle);
                self.menuToggleClass();
                App.Global.goToSection(targetId);

            }


        });

    },

    navToggleClass: function($that) {

        var self = this;
        $that.toggleClass('open');

    },

    menuToggleClass: function() {

        var self = this;

        if(self.$navigationMenu.is(':hidden')) {
            self.$navigationMenu.show(20, function() {
                self.$navigationMenu.addClass('open');
            });
        } else {
            self.$navigationMenu.removeClass('open');
            setTimeout(function() {
                self.$navigationMenu.hide();
            }, 190)
        }

    },

    toggleTimer: function(state) {

        var self = this;

        if(state === true) {
            self.timer = setInterval(function() {
                if (self.didScroll) {
                    self.toggleNavigationDisplay();
                    self.didScroll = false;
                }
            }, 250);
        }else{
            clearInterval(self.timer);
        }


    },

    toggleNavigationDisplay: function() {

        var self = this;
        var st = jQuery(window).scrollTop();

        if(Math.abs(self.lastScrollTop - st) <= self.delta)
            return;

        if (st > self.lastScrollTop && st > self.navigationHeight){
            self.$navigation.addClass('navigation--up js-navigation-up');
        } else {
            if(st + jQuery(window).height() < jQuery(document).height()) {
                self.$navigation.removeClass('navigation--up js-navigation-up');
            }
        }

        if(jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) {
            self.$navigation.removeClass('navigation--up js-navigation-up');
        }

        self.lastScrollTop = st;

    },

    manageCurrentClass: function(index) {

        var self = this;
        self.$navigationLink.removeClass("current");
        self.$navigationMenu.find("> li:eq("+ index +") > a").addClass("current");

    },

    goToReferences: function(target) {

        var slideIndex = jQuery('div[data-target="'+target+'"]').attr("data-slide");
        jQuery.fn.fullpage.moveTo(2);
        setTimeout(function() {
            App.Layer.displayFullScreenLayer(App.Reference.$referenceSliderBody);
        }, 950);
        setTimeout(function() {
            App.Reference.$referenceSliderBody.owlCarousel('to', slideIndex, 500, true);
        }, 1750)

    }

}
