"use strict";

App.Layer = {

    layer          : '.js-layer',
    closeLayer     : '.js-layer-close',
    closeLayerHtml : '<a class="layer__close js-layer-close" />',

    init: function() {

        this.$layer      = jQuery(this.layer);
        this.$closeLayer = jQuery(this.closeLayer);
        this.isopen      = false;
        this.addListeners();

    },

    addListeners: function() {

        var self = this;

        jQuery(document).on('click', self.closeLayer, function() {

            var $that = jQuery(this);
            self.hideFullScreenLayer(App.Reference.$referenceSliderBody);
            App.Navigation.navToggleClass($that);

        });

    },

    toggleLayerDisplay: function($trigger, $layer) {

        var self = this;

        $layer.toggleClass("layer--active");

        if(!$layer.hasClass("layer--slide") && !$layer.hasClass("layer--fade")) {

            self.displayFullScreenLayer($layer);

        }else{

            if($trigger.hasClass("js-plusminus--slide")) {

                App.Reference.toggleView();

            }

        }

    },

    displayFullScreenLayer: function($layer) {

        var self = this;

        jQuery.fn.fullpage.setMouseWheelScrolling(false);
        jQuery.fn.fullpage.setAllowScrolling(false);

        App.Scroll.$scroll.fadeOut();
        App.Navigation.$navigation.removeClass('navigation--up');

        $layer.addClass("ready");
        App.Reference.$referenceSliderBody.owlCarousel('refresh');

        setTimeout(function() {
            if(!jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider").hasClass('owl-loaded')) {
                App.Video.initVideoSlider(jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider"));
            }
        }, 200);

        setTimeout(function() {
            jQuery(App.Reference.referenceSection).addClass("section-layer-open");
            $layer.addClass("display");
            jQuery(document).trigger('display');
        }, 500);

        setTimeout(function() {
            App.Reference.$referenceSliderBody.owlCarousel('refresh');
        }, 700);

        self.fullScreenLayerControl('open');

    },

    hideFullScreenLayer: function($layer) {

        var self = this;

        self.fullScreenLayerControl('close');

        setTimeout(function() {

            jQuery(App.Reference.referenceSection).removeClass("section-layer-open");
            $layer.removeClass("display");

        }, 1200);

        setTimeout(function() {
            $layer.removeClass("ready");
            jQuery.fn.fullpage.setMouseWheelScrolling(true);
            jQuery.fn.fullpage.setAllowScrolling(true);
            App.Scroll.$scroll.fadeIn();
            App.Navigation.$navigation.addClass('navigation--up');
        }, 1500);

    },

    fullScreenLayerControl: function(state) {

        var self = this;

        if(state === 'open') {

            if(jQuery(self.closeLayer).length === 0) {

                jQuery(self.closeLayerHtml).html(
                    '<span class="navigation__toggle__icon">'+
                    '<span></span>'+
                    '<span></span>'+
                    '</span>'+
                    '<span class="layer__close__label">Revenir aux références</span>'
                ).appendTo('body');

            }

            setTimeout(function() {
                jQuery(self.closeLayer).addClass('visible');
            }, 1000);
            setTimeout(function() {
                jQuery(self.closeLayer).addClass('ready');
            }, 1100);

        } else {

            jQuery(self.closeLayer).removeClass('ready');
            setTimeout(function() {
                jQuery(self.closeLayer).removeClass('visible');
            }, 900);

        }

    }

}
