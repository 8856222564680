"use strict";

App.Scroll = {

    scroll   : '.js-scroll',

    init: function() {

        this.$scroll = jQuery(this.scroll);
        this.addListeners();

    },

    addListeners: function() {

        var self = this;

        self.$scroll.on('click', function() {

            var $that = jQuery(this);

            if(jQuery('.fp-destroyed').length == 0) {

                if($that.hasClass("scroll--up")) {

                    App.Global.gotToSectionUp();

                }else{

                    App.Global.gotToSectionDown();

                }

            }else{

                App.Global.goToSection('references');

            }

        });

    }

}
