"use strict";

// @TODO Configure sliders with infiniteloop.
// Problem today : empty slide generated/

App.Reference = {

    referenceSection          : '.js-section-reference',
    referenceSliderHeader     : '.js-reference-slider-header',
    referenceSliderBody       : '.js-reference-slider-body',
    referenceSliderBodyItem   : '.js-slide',
    referenceTrigger          : '.js-reference-trigger',
    referenceCounter          : '.js-reference-counter',
    referenceTitle            : '.js-reference-title',
    reference                 : '.js-reference',

    init: function() {

        this.$referenceSection          = jQuery(this.referenceSection);
        this.$referenceSliderHeader     = jQuery(this.referenceSliderHeader);
        this.$referenceSliderBody       = jQuery(this.referenceSliderBody);
        this.$referenceSliderBodyItem   = jQuery(this.referenceSliderBodyItem);
        this.$referenceTrigger          = jQuery(this.referenceTrigger);
        this.$referenceCounter          = jQuery(this.referenceCounter);
        this.$reference                 = jQuery(this.reference);
        this.addListeners();

        enquire.register("screen and (max-width:" + App.bp_phone_only + "px)", {

            setup: function() {

                App.Reference.$referenceSliderHeader.owlCarousel('destroy');

            },

            match: function() {

                if(!App.Reference.$referenceSliderHeader.hasClass("owl-loaded")) {
                    App.Reference.initHeaderSlider();
                }

            },

            unmatch: function() {

                App.Reference.$referenceSliderHeader.owlCarousel('destroy');

            }

        });

    },

    addListeners: function() {

        var self = this;

        // When header slider is initialized
        self.$referenceSliderHeader.on('initialized.owl.carousel', function(event) {

            setTimeout(function(){

                // Init body slider
                if(!self.$referenceSliderBody.hasClass("owl-loaded")) {
                    self.initBodySlider();
                }

                // Create slides counter
                self.createCounter(event, 'init');
                // Move title
                self.displayTitle(event, 'init');

                // Move plusminus control
                self.$referenceSliderHeader.parents(App.Section.section).find(App.PlusMinus.plusminus).appendTo('.js-reference-slider-header .owl-nav');

            }, 250);

        });

        // Reference body slide controls with header slider
        self.$referenceSliderHeader.on('changed.owl.carousel', function(event) {

            if (!event.namespace || event.property.name != 'position') return;

            var target = event.relatedTarget.relative(event.property.value, true);
            var items_per_page = event.page.size;
            var offset = jQuery(event.target).find('.active:first').prevAll('.cloned').length;
            var total = event.relatedTarget.items().length;

            if (items_per_page > 1){
                var min_item_index  = event.item.index,
                    max_item_index  = min_item_index + items_per_page,
                    display_text    = (min_item_index+1) + '-' + max_item_index;

            } else {

                var display_text = (event.item.index - offset);

            }

            if(display_text === -1){
                display_text = total - 1;
            }

            self.createCounter(event, 'update', display_text);
            self.displayTitle(event, 'update', display_text);
            self.$referenceSliderBody.owlCarousel('to', display_text, 500, true);

        });

        // Display reference layer on device up to mobile
        self.$referenceTrigger.on("click", function() {

            var $that = jQuery(this);
            var target = $that.attr("data-slide");
            App.Layer.toggleLayerDisplay($that, self.$referenceSliderBody);

            jQuery(document).bind('display',function(){
                setTimeout(function() {
                    self.goToSlideFullScreen(target);
                }, 250);
            });

        });

        // Reference video init on slide change
        self.$referenceSliderBody.on('translated.owl.carousel', function(event) {

            App.Video.$video.each(function(idx, el){

                jQuery(this)[0].pause();
                jQuery(this)[0].currentTime = 0;

            })

            if(!jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider").hasClass('owl-loaded')) {
                App.Video.initVideoSlider(jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider"));
            }

            setTimeout( function() {

                jQuery("#reference_slider_body.scroll-enable").scrollTop(0)

            }, 200 );

        });

    },

    initHeaderSlider: function() {

        var self = this;

        self.$referenceSliderHeader.owlCarousel({
            loop: true,
            items: 1,
            nav: true,
            dots: false,
            smartSpeed: 450,
            lazyLoad: true
        });

    },

    initBodySlider: function() {

        var self = this;

        self.$referenceSliderBody.owlCarousel({
            dots: false,
            autoHeight: true,
            lazyLoad: false,
            responsive: {

                0 : {
                    items: 1,
                    nav: false,
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false,
                    loop: true,
                },

                600 : {
                    items: 1,
                    nav: true,
                    touchDrag: true,
                    navText: ["Projet<small></small>précédent", "Projet<small></small>suivant"],
                    smartSpeed: 800,
                    loop: true,
                },

            },
        });

    },

    createCounter: function(event, task, displaytext) {

        var self = this;

        if (!event.namespace)  {
            return;
        }

        var carousel = event.relatedTarget;

        if(task === 'init') {
            self.$referenceCounter.addClass("init");
            self.$referenceCounter.html('<span class="references__count--current">1</span> | ' + carousel.items().length + ' <span class="references__count--sep"></span><span class="js-reference-title"></span>');
            self.$referenceCounter.appendTo('.js-reference-slider-header .owl-nav').show();
        } else {
            if(displaytext === -1) {

                displaytext = carousel.items().length;

            }else{

                displaytext = displaytext + 1;

            }
            self.$referenceCounter.html('<span class="references__count--current">' + displaytext + '</span> | ' + carousel.items().length + ' <span class="references__count--sep"></span><span class="js-reference-title"></span>');
        }


    },

    displayTitle: function(event, task, displaytext) {

        var self = this;
        var currentItemTitle;

        if(task === 'init') {
            currentItemTitle = self.$referenceSliderBodyItem.eq(0).attr("data-title");
        }else{
            if(displaytext === -1){
                displaytext = 1;
            }
            currentItemTitle = self.$referenceSliderBodyItem.eq(displaytext).attr("data-title");
        }
        jQuery(self.referenceTitle).text(currentItemTitle);

    },

    goToSlideFullScreen: function(target) {

        var self = this;

        self.$referenceSliderBody.owlCarousel('to', target-1, 400, true);

    },

    toggleView: function() {

        var self = this;
        var fixed = document.body;

        if(!self.$referenceSliderHeader.hasClass("fixed-state")) { // open

            // Disable nav timer to avoid menu display
            App.Navigation.toggleTimer(false);

            // Hide navigation if visible
            App.Navigation.$navigation.removeClass("navigation--up");

            // Scroll to header section
            jQuery('html,body').animate({
                scrollTop: jQuery(self.$referenceSliderHeader).offset().top
            }, 500, "easeInOutExpo").promise().done(function(){

                // Fix header section
                self.$referenceSliderHeader.addClass("fixed-state");

                // Enable scroll on it and trigger refresh
                self.$referenceSliderBody.addClass("scroll-enable");

                // Disable body scroll
                jQuery('body, html, .site-main').addClass("scroll-disable");

                // Init body slider
                if(!self.$referenceSliderBody.hasClass("owl-loaded")) {
                    self.initBodySlider();
                    self.$referenceSliderBody.trigger('refresh.owl.carousel');
                }
                setTimeout( function() {

                    jQuery("#reference_slider_body.scroll-enable").scrollTop(0)

                }, 200 );

                //self.stopBodyScrolling(true);

                // jQuery.fn.fullpage.setMouseWheelScrolling(false);
                // jQuery.fn.fullpage.setAllowScrolling(false);

                // fixed.addEventListener('touchmove', function(e) {
                //
                //         e.preventDefault();
                //
                // }, false);

                if(!jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider").hasClass('owl-loaded')) {
                    App.Video.initVideoSlider(jQuery("#reference_slider_body > div > div > .owl-item.active .js-video-slider"));
                }

            });


        }else{

            // Remove scroll on reference body height
            self.$referenceSliderBody.removeClass("scroll-enable");

            // Remove scroll on mobile
            jQuery('body, html, .site-main').removeClass("scroll-disable");

            // Remove header fixed position
            self.$referenceSliderHeader.removeClass("fixed-state");

            //self.stopBodyScrolling(false);

            // Restore timer for navigation on scroll
            App.Navigation.toggleTimer(true);

            // Wait until slideUp complete to refresh autoheight
            self.$referenceSliderBody.trigger('refresh.owl.carousel');

        }

    },

    stopBodyScrolling: function(bool) {

        var freezeVp = function(e) {
            e.preventDefault();
        };

        if (bool === true) {
            document.body.addEventListener("touchmove", freezeVp, false);
        } else {
            document.body.removeEventListener("touchmove", freezeVp, false);
        }

    }

}
