"use strict";

App.Video = {

    videoSlider   : '.js-video-slider',
    videoLauncher : '.js-video-launcher',
    videoStopper  : '.js-video-stopper',
    video         : '.js-video',
    videoWrapper  : '.js-video-wrapper',
    videoHide     : '.js-hide-for-video',

    init: function() {

        this.$videoSlider   = jQuery(this.videoSlider);
        this.$videoLauncher = jQuery(this.videoLauncher);
        this.$videoStopper  = jQuery(this.videoStopper);
        this.$video         = jQuery(this.video);
        this.$videoWrapper  = jQuery(this.videoWrapper);
        this.$videoHide     = jQuery(this.videoHide);
        this.isPlaying      = false;
        this.addListeners();

    },

    addListeners: function() {

        var self = this;

        jQuery(document).on("click", self.videoLauncher, function() {

            var $that = jQuery(this);
            self.videoControls($that, 'play');
            if(jQuery(window).width() <= 599) {

                self.$video.attr("controls", "");

            }

        });

        jQuery(document).on("click", self.videoStopper, function() {

            var $that = jQuery(this);
            self.videoControls($that, 'stop');

            if(jQuery(window).width() <= 599) {

                self.$video.removeAttr("controls");

            }

        });

        self.$video.hover(function(event) {

            if(jQuery(window).width() > 1200) {

                if(event.type === "mouseenter") {
                    jQuery(this).attr("controls", "");
                } else if(event.type === "mouseleave") {
                    jQuery(this).removeAttr("controls");
                }

            }

        });

    },

    initVideoSlider: function($slider) {

        var self = this;
        var item_count = parseInt($slider.find('.video').length);

        // If there is only three slides
        if (item_count == 1) {
            // Set loop option variable to false
            var isLooped = false;
            // Set nav option variable to false
            var isNav = false;
            // Set dots option variable to false
            var isDots = false;
        }
        else {
            // Set loop option variable to true
            var isLooped = true;
            // Set nav option variable to true
            var isNav = true;
            // Set dots option variable to true
            var isDots = true;
        }

        // When header slider is initialized
        $slider.on('initialized.owl.carousel', function(event) {

            // If there is only three slides
            if (item_count == 1) {
                // Set loop option variable to false
                var isLooped = false;
                // Set nav option variable to false
                var isNav = false;
                // Set dots option variable to false
                var isDots = false;

                $slider.addClass("solo");
            }
            else {
                // Set loop option variable to true
                var isLooped = true;
                // Set nav option variable to true
                var isNav = true;
                // Set dots option variable to true
                var isDots = true;
            }

        });

        $slider.owlCarousel({
            loop:isLooped,
            items: 1,
            nav: isNav,
            dots: isDots,
            navContainerClass: "owl-vid-nav",
            navClass: ["owl-vid-prev", "owl-vid-next"],
            smartSpeed: 500,
            autoHeight:true
        });

    },

    videoControls: function($launcher, state) {

        var self = this;

        if(state === 'play') {

            self.$videoHide.addClass('hide');
            $launcher.parents('.references__gallery').find('.owl-vid-prev, .owl-vid-next, .video').addClass("playing");
            $launcher.addClass("playing");

            if($launcher.hasClass('video__launcher--top')) {
                jQuery('.video__launcher--under, .video__stop--under')
                jQuery('.video__stop--top').addClass('playing');
                $launcher.next(self.$videoWrapper).find(self.$video)[0].play();
            }else{
                $launcher.next().next().addClass("playing")
                $launcher.next(self.$video)[0].play();
            }


        } else {

            if($launcher.hasClass('video__launcher--top') || $launcher.hasClass('video__stop--top')) {

                $launcher.prev(self.$videoWrapper).find(self.$video)[0].pause();
                $launcher.removeClass("playing");
                $launcher.prev().prev().removeClass("playing");
                $launcher.parents('.references__gallery').find('.owl-vid-prev, .owl-vid-next, .video').removeClass("playing");

            }else{

                $launcher.prev(self.$video)[0].pause();
                $launcher.removeClass("playing");
                $launcher.prev().prev().removeClass("playing")
                $launcher.parents('.references__gallery').find('.owl-vid-prev, .owl-vid-next, .video').removeClass("playing");

            }

            self.$videoHide.removeClass('hide').addClass("show");

        }

    }

}
