"use strict";

App.PlusMinus = {

    plusminus       : '.js-plusminus',
    plusminusToggle : '.js-plusminus-toggle',
    plusMinusFade   : '.js-plusminus--fade',
    plusMinusSlide  : '.js-plusminus--slide',

    init: function() {

        this.$plusminus       = jQuery(this.plusminus);
        this.$plusminusToggle = jQuery(this.plusminusToggle);
        this.addListeners();

    },

    addListeners: function() {

    var self = this;
    jQuery(document).on('click', self.plusminus, function(e) {
        var $that   = jQuery(this);
        var $layer = $that.parents(App.Section.section).find(App.Layer.$layer);
        self.toggleContent($that, $layer);
    });

    },

    toggleContent: function($that, $layer) {

        var self = this;
        $that.toggleClass('plusminus--active');
        $that.find(self.plusminusToggle).toggleClass('plusminus__toggle--active');
        App.Layer.toggleLayerDisplay($that, $layer);

    }

}
